import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import GlobalWrapper from 'components/global-wrapper'
import Header from 'components/article/header'
import CoverImage from 'components/article/coverimage'
import ContentWrapper from 'components/article/contentwrapper'
import Footer from 'components/footer'
import TwoImage from 'components/article/twoimage'
import LargeImage from 'components/article/largeimage'


import Img2 from 'img/articles/project1/rese.jpg'


const Section = styled.section`
  margin: 64px 0;
  ${media.sm`
    margin: 0;
  `}
`

const Project2 = () => {
  return (
    <GlobalWrapper>
      <Header title="Rock 'em Sock 'em" />
      <CoverImage src={Img2} focusX={'28%'} focusY={'70%'} />
      <Section>
        <ContentWrapper>
          <h2>Rock 'em Sock 'em Robots</h2>

          <p>
          Introducing the larger-than-life project of the LifeSize Rock 'Em Sock 'Em Robots at Dave and Buster's, an exhilarating attraction that brings the classic game to life on an epic scale. As a passionate engineer with expertise in mechanical design, electrical engineering, and project management, I had the honor of leading the development of this extraordinary project.
          With meticulous attention to detail and a deep understanding of mechanical principles, I embarked on the task of designing and engineering the LifeSize Rock 'Em Sock 'Em Robots. From conceptualization to realization, every aspect of the robots was carefully considered to capture the essence of the beloved game while delivering a captivating and immersive experience.
          The mechanical design involved creating life-size robotic structures capable of dynamic movements and powerful punches. Through careful selection of materials, precision engineering, and rigorous testing, I ensured the robots could withstand intense gameplay while maintaining a safe and enjoyable experience for players. Balancing the delicate dance between size, weight, and maneuverability, I crafted robust mechanical systems that delivered both performance and durability.
          As the director of electrical engineering, I oversaw the integration of electrical components that brought the robots to life. With expertise in circuit design, motor control systems, and sensor integration, I carefully crafted the electrical architecture to enable precise and responsive movements. By seamlessly incorporating technology, I ensured an immersive experience where players could control the robots and feel every punch as if they were in the ring themselves.
          Project management played a crucial role in guiding the LifeSize Rock 'Em Sock 'Em Robots to success. From coordinating a multidisciplinary team to managing timelines, resources, and stakeholder expectations, I orchestrated the project's various aspects with diligence and professionalism. Through effective communication and collaborative problem-solving, I fostered a cohesive and motivated team that brought the project to fruition.
          The LifeSize Rock 'Em Sock 'Em Robots at Dave and Buster's stand as a testament to the harmonious blend of mechanical design, electrical engineering, and project management. By combining creativity, technical expertise, and a passion for innovation, I have delivered an unforgettable attraction that captures the imagination of players young and old. Experience the thrill of the LifeSize Rock 'Em Sock 'Em Robots and witness the result of my unwavering dedication to excellence in engineering and entertainment.
          For more information about my work and potential collaborations, please explore the website or reach out to discuss exciting projects in the field of mechanical design, electrical engineering, or project management. Get ready to step into the ring and unleash the excitement of the LifeSize Rock 'Em Sock 'Em Robots at Dave and Buster's.
          </p>

          
        </ContentWrapper>
      </Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default Project2
